/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Container from '@material-ui/core/Container'
import NavBar from '../../components/shared/NavBar'
import Footer from '../../components/shared/Footer'
import { makeStyles } from '@material-ui/core/styles';

import "./styles.sass"

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    minHeight: "100vh",
  },
  secondaryBackgroundColor: {
      backgroundColor: theme.palette.secondary.main
  },
  overflowHidden: {
    overflowX: "initial"
  }
}));

const Layout = ({ children, location, landingPage, secondaryBackgroundColor }) => {

  const reference = useRef(null)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    const classes = useStyles()


  return (
    <Container ref={reference} disableGutters className={`${classes.fullHeight} ${secondaryBackgroundColor && classes.secondaryBackgroundColor}`} maxWidth={false}>

        <Container>
          <NavBar reference={ reference } location={location}/>
          </Container>

          {children}
          {/* <footer>
            © {new Date().getFullYear()},
            {` `}
            <a href="https://www.inspirafarms.com">InspiraFarms</a>
          </footer> */}


          <Footer/>

        </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout