import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from '@material-ui/core/styles';
import LogoSVG from '../../assets/logo.svg';

const useStyles = makeStyles((theme) => ({
  svg: {
    marginTop: '8px',
    height: '50px',
    width: '50px'
  }
}))


const NavLogo = ({ }) => {
  const classes = useStyles()

  return (
    <div className="logo">
      <Link to="/">
        <LogoSVG className={classes.svg}></LogoSVG>
        {/* <img src={LogoSVG} alt="logo" /> */}
      </Link>
    </div>
  );
};

NavLogo.propTypes = {
};

NavLogo.defaultProps = {
};

export default NavLogo;
