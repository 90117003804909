import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {Box, Grid} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import { Link, Button } from "gatsby-theme-material-ui";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NavLogo from "./NavLogo"
import { globalHistory as history } from '@reach/router'
import { FullscreenExit } from '@material-ui/icons';


const WhiteLink = withStyles((theme) => ({
    root: {
      color: "#FFFFFF",
      textDecoration: "none",
      fontWeight: 400,
      paddingBottom: theme.spacing(2),
      textAlign: "center"
    }
  }))(Link);

const useStyles = makeStyles((theme) => ({
    root: {
        // height: 500,
        justifyContent: "center",
        boxShadow: "none",
        background: theme.palette.primary.main,
      },
      title: {
          color: "#FFF",
          textAlign: "center",
          fontWeight: "600",
          paddingBottom: theme.spacing(3)
      },
      grid: {
          padding: `${theme.spacing(9)}px 0px `,
          display: 'flex',
          flexDirection: 'column',

      },
  container: {
    maxWidth: 1050,
  },
  callToActionButton: {
    marginLeft: "auto"
  }
}));

export default function HideAppBar({ children, window }) {
  //const classes = useStyles()


  return (
      <>
      {/* <CssBaseline />
          <Box className={classes.root} color="primary">
          <Container className={classes.container}>
              <Grid container>
                <Grid item xs={4} className={classes.grid}>
                    <Typography variant="body1" className={classes.title}> Social Media</Typography>
                    <WhiteLink variant="body1" to="https://www.linkedin.com/company/-nspirafarms/">LinkedIn</WhiteLink>
                    <WhiteLink variant="body1" to="https://twitter.com/InspiraFarms">Twitter</WhiteLink>
                    <WhiteLink variant="body1" to="https://www.facebook.com/inspirafarms/">Facebook</WhiteLink>
                    <WhiteLink variant="body1" to="https://www.instagram.com/inspirafarms_ig/">Facebook</WhiteLink>
                    
                </Grid>
                <Grid item xs={4} className={classes.grid}>
                    <Typography variant="body1" className={classes.title}> Company</Typography>
                    <WhiteLink variant="body1" to="https://www.inspirafarms.com/">Homepage</WhiteLink>
                    <WhiteLink variant="body1" to="https://www.inspirafarms.com/insights/">Insights</WhiteLink>
                    <WhiteLink variant="body1" to="https://www.inspirafarms.com/about-us/">About Us</WhiteLink>
                    <WhiteLink variant="body1" to="https://www.inspirafarms.com/contact">Contact</WhiteLink>

                </Grid>
                <Grid item xs={4} className={classes.grid}>
                    <Typography variant="body1" className={classes.title}> Products</Typography>
                    <WhiteLink variant="body1" to="https://www.inspirafarms.com/financing-3/">Financing</WhiteLink>
                    <WhiteLink variant="body1" to="https://www.inspirafarms.com/products/">Products</WhiteLink>
                    <WhiteLink variant="body1" to="https://www.inspirafarms.com/technical-services-2/">Services</WhiteLink>
                    <WhiteLink variant="body1" to="https://www.inspirafarms.com/about-us/">About Us</WhiteLink>
                </Grid>
              </Grid>
            <Toolbar>
              <Typography variant="h6">
              </Typography>
            </Toolbar>
            </Container>
          </Box> */}
      </>
  );
}