import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import { Link, Button } from "gatsby-theme-material-ui";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NavLogo from "./NavLogo"
import { globalHistory as history } from '@reach/router'
import { useScroll, useWheel } from 'react-use-gesture'


const CustomAppBar = withStyles((theme) => ({
  root: {
    height: 100,
    justifyContent: "center",
    boxShadow: "none"
  },
}))(AppBar);

const NavLink = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textDecoration: "none",
    fontSize: 18,
    textAlign: "center",
    paddingLeft: theme.spacing(2),
  }
}))(Link);

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1050,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: 0,
    },
  },
  callToActionButton: {
    marginLeft: "auto"
  },
  navLinkWrapper: {
  }
}));

function HideOnScroll({ children }) {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
  }

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function NavBar({ children, reference }) {
  const { location, navigate } = history
  const classes = useStyles()
  const [hasScrolledDown, setScrolledDown] = useState(false)

  const bind = useWheel(({ xy: [x,y], distance  }) => {
    const scrollThreshold = 200

    if (!hasScrolledDown && distance > scrollThreshold) {
      setScrolledDown(true)
    }
  }, { domTarget: reference})

  const checkIfExistsInString = (stringToCheck, stringToSearchFor) => {
    return stringToCheck.indexOf(stringToSearchFor) > -1
  }

  const isConfiguratorPage = checkIfExistsInString(location.pathname, 'configurator')
  const isLandingPage = location.pathname === "/"

  return (
      <>
      <CssBaseline />
      <HideOnScroll {...bind()} children={children}>
          <CustomAppBar  color="transparent">
          <Container className={classes.container}>
            <Toolbar>
              <NavLogo/>
              <Typography variant="h6">
              </Typography>
              {(isConfiguratorPage) && <div className={classes.navLinkWrapper}><NavLink varian="body2" to="/">More Information</NavLink></div>}
              {(!isConfiguratorPage && hasScrolledDown) && <Button className={classes.callToActionButton} variant="contained" color="primary" to="/configurator">Configure Now!</Button>}
            </Toolbar>
            </Container>
          </CustomAppBar>
      </HideOnScroll>
      <Toolbar />
      </>
  );
}